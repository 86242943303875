//
// Layout Initialization
//

// Import Dependencies
@import './../core/layout/base';
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'print';
@import 'main';
@import 'header';
@import 'toolbar';
@import 'aside/base';
@import 'aside/menu';
@import 'content';
@import 'requests';
@import 'footer';
