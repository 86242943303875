//
// Request
//
body.iframe-body {
  .request-page {
    width: 100%;
  }
}
.request-page {
  background-size: cover;
  background-position: center center;
  font-family: Poppins, Helvetica, 'sans-serif';
  .donationloader {
    .progress {
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: transparent;
      box-shadow: rgb(0 0 0 / 12%) 0px -23px 25px 0px inset, rgb(0 0 0 / 10%) 0px -36px 30px 0px inset, rgb(0 0 0 / 8%) 0px -79px 40px 0px inset, rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 4%) 0px 4px 2px, rgb(0 0 0 / 4%) 0px 8px 4px, rgb(0 0 0 / 4%) 0px 16px 8px, rgb(0 0 0 / 4%) 0px 32px 16px;
      --bs-progress-height: 2.5rem;
      --bs-progress-font-size: 1rem;
      position: relative;
      .progress-bar {
        > div {
          position: absolute;
          left: 0;
          right: 0;
        }
      }
    }
  }
  .donationfeed {
    .donationscroller {
      &::-webkit-scrollbar-thumb {
        background: rgba(0,0,0,0.5)
      }
    }
  }
  .st-inline-share-buttons {
    padding: 0!important;
    margin: 0!important;
    .st-btn {
      border: 0!important;
      line-height: normal !important;
      padding-top: 0.6rem !important;
      padding-bottom: 0.6rem !important;
      padding-right: 1.25rem !important;
      padding-left: 1.25rem !important;
      outline: none !important;
      background: var(--background) !important;
      color: var(--color) !important;
      font-size: 0.925rem !important;
      min-width: unset !important;
      border-radius: 0.325rem !important;
      height: auto !important;
      &:hover {
        top: 0!important;
        opacity: 1 !important;
        background: #181c32 !important;
        .st-label {
          color: white !important;
        }
      }
      img {
        display: none !important;
      }
      .st-label {
        display: inline-block !important;
        color: var(--color) !important;
        font-family: Poppins, Helvetica, 'sans-serif' !important;
        font-size: 0.925rem !important;
        font-weight: 500 !important;
        line-height: 1.5 !important;
        min-width: unset !important;
        padding: 0 !important;
      }
    }
  }
  .content-fluid {
    .card {
      max-width: 550px;
      width: 550px;
      min-width: 100px;
      &.card-extra {
        max-width: 3500px;
        width: 350px;
      }
      .card-body {
        .card-top {
          img {
            max-width: 140px;
            max-height: 60px;
            object-fit: contain;
          }
          svg {
            fill: #707070;
            color: #707070;
          }
        }
        .personal__menu {
          a {
            &:hover,
            &:focus,
            &.active {
              color: var(--color);
              background: var(--background) !important;
            }
          }
        }
        .personal__pricing {
          gap: 0;
          row-gap: 0;
          grid-template-columns: repeat(3, 1fr);
          /*grid-template-rows: repeat(3, 1fr);*/
          grid-column-gap: 0;
          grid-row-gap: 0;
          overflow: hidden;
          .pr__definedamounts {
            color: var(--color);
            cursor: pointer;
            border-right: 1px solid rgba(255,255,255,0.1);
            border-bottom: 1px solid rgba(255,255,255,0.1);
            transition: background .3s ease-in-out;
            &:nth-child(3n) {
              border-right: none;
            }
            &:hover {
              background: rgba(255,255,255,0.1);
            }
            &.active {
              background: rgba(255,255,255,0.2);
              border-right-color: transparent;
              border-bottom-color: transparent;
            }
            .pr__cents {
              font-size: 0.8em;
            }
          }
          .pr__fullwidth {
            grid-column: span 3;
            &:hover {
              background: rgba(255,255,255,0.1);
            }
            &.active {
              background: rgba(255,255,255,0.2);
            }
            input {
              background: transparent;
              outline: none;
              color: var(--color);
              &:active, &:focus {
                outline: none;
              }
              &::placeholder {
                color: var(--color);
                opacity: 0.6;
                font-weight: lighter;
              }
            }
          }
        }
        & > img {
          object-fit: cover;
          width: 100%;
        }
        h2 {
          color:#161616;
          font-weight: 500;
        }
        p {
          text-align: justify;
          color: #333;
          /*font-size: .9rem;*/
        }
        .pr__customer_fields {
          input, textarea {
            border-color: var(--field-border) !important;
            box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
          }
          &.mollie_fields {
            input, textarea {
              border-color: var(--btn-background1) !important;
            }
          }
        }
        .productsgroup{
          .productbar {
            transition: all .3s ease-in-out;
            &.rounded {
              border: 1px solid var(--coloractive);
            }
          }
        }
        .pricing-container {
          color:#161616;
          font-weight: 600;
          font-size: 2rem;
          &:not(.personal-container) {
            svg {
              font-size: 2rem;
            }
          }
          & > span:not(.pe-2):not([aria-busy]) {
            display: inline-flex;
            flex-direction: column;
            flex-grow: 1;
            .pricing {
              line-height: 1.8rem;
            }
            .sub-repeat {
              font-size: 0.9rem;
              width: 100%;
              color: #707070;
              font-weight: 400;
            }
          }
        }
        .payment__icons {
          gap: 10px;
          row-gap: 10px;
          grid-template-columns: repeat(4, 1fr);
            /*grid-template-rows: repeat(3, 1fr);*/
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          button {
            box-shadow: inset 0 0 0 2px #f5f8fa;
            transition: color 0.25s,
              transform 0.3s ease-in-out;
            position: relative;
            overflow: hidden;
            &::before,
            &::after {
              box-sizing: inherit;
              content: '';
              position: absolute;

              border-radius: var(--bs-btn-border-radius);
              border: 2px solid transparent;
              width: 0;
              height: 0;
            }
            &::before {
              top: 0;
              left: 0;
            }
            &::after {
              bottom: 0;
              right: 0;
              top: 0;
              left: 0;
            }
            &:hover {
              color: var(--btn-background1);
            }
            &:hover::before,
            &:hover::after {
              width: 100%;
              height: 100%;
            }
            &:hover::before {
              border-top-color: var(--btn-background1); // Make borders visible
              border-right-color: var(--btn-background1);
              transition:
                      width 0.35s ease-in-out, // Width expands first
                      height 0.35s ease-in-out 0.35s; // And then height
            }

            &:hover::after {
              border-bottom-color: var(--btn-background1); // Make borders visible
              border-left-color: var(--btn-background1);
              transition:
                      height 0.35s ease-in-out,
                      width 0.35s ease-in-out 0.35s;
            }
          }
          a {
            aspect-ratio: 1.14;
          }
        }
      }
    }
  }
  .secured__badge {
    align-items: center;
    bottom: 20px;
    display: flex;
    justify-content: center;
    margin: 0 auto 6px;
    opacity: .6;
    position: fixed;
    right: 30px;
    text-decoration: none!important;
    .secured__badge__container {
      align-items: center;
      background-color: #000;
      border-radius: 0 0 3px 3px;
      display: flex;
      justify-content: center;
      padding: 6px 12px;
      .secured__badge__text {
        color: #fff;
        font-size: 11px;
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }

  .pr__next {
    background: linear-gradient(121deg, var(--btn-background1) 0%, var(--btn-background2)  100%);
    transition: all .3s ease;
    &:hover {
      filter: brightness(1.1);
    }
  }

  .pr__stepper-enter,
  .pr__stepper-enter-active {
    opacity: 0;
    transform: scale(0.9) translateY(-20px);
  }
  .pr__stepper-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in, transform 300ms ease-out;
  }
  .pr__stepper-exit {
    opacity: 1;
  }
  .pr__stepper-exit-active {
    opacity: 0;
    transform: scale(0.9) translateY(-20px);
    transition: opacity 300ms ease-out, transform 300ms ease-in;
  }

  .filehandler {
    overflow: hidden;
    & > div{
      &:last-child {
        border-bottom: none;
        .btn-light-danger {
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0.475rem !important;
        }
      }
      border-bottom: 1px dotted grey;
      &:first-child {
        .btn-light-danger {
          border-top-right-radius: 0.475rem !important;
        }
      }
    }
  }

  .socialicon {
    box-shadow: inset 0 0 0 2px #f5f8fa;
    transition: color 0.25s,
    transform 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;

      border-radius: var(--bs-btn-border-radius);
      border: 2px solid transparent;
      width: 0;
      height: 0;
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
    }
    &:hover {
      color: var(--btn-background1);
    }
    &:hover::before,
    &:hover::after {
      width: 100%;
      height: 100%;
    }
    &:hover::before {
      border-top-color: var(--btn-background1); // Make borders visible
      border-right-color: var(--btn-background1);
      transition:
              width 0.35s ease-in-out, // Width expands first
              height 0.35s ease-in-out 0.35s; // And then height
    }

    &:hover::after {
      border-bottom-color: var(--btn-background1); // Make borders visible
      border-left-color: var(--btn-background1);
      transition:
              height 0.35s ease-in-out,
              width 0.35s ease-in-out 0.35s;
    }
  }

  .fa-500px {
    color: #0099e5!important;
  }
  .fa-adn {
    color: #4A484C!important;
  }
  .fa-amazon {
    color: #ff9900!important;
  }
  .fa-android {
    color: #a4c639!important;
  }
  .fa-angellist {
    color: #000!important;
  }
  .fa-apple {
    color: #979797!important;
  }
  .fa-behance {
    color: #1769ff!important;
  }
  .fa-behance-square {
    color: #1769ff!important;
  }
  .fa-bitbucket {
    color: #205081!important;
  }
  .fa-bitbucket-square {
    color: #205081!important;
  }
  .fa-bitcoin {
    color: #ee9209!important;
  }
  .fa-black-tie {
    color: #222!important;
  }
  .fa-btc {
    color: #ee9209!important;
  }
  .fa-buysellads {
    color: #c90100!important;
  }
  .fa-cc-amex {
    color: #007bc1!important;
  }
  .fa-cc-diners-club {
    color: #004A97!important;
  }
  .fa-cc-discover {
    color: #f68121!important;
  }
  .fa-cc-jcb {
    color: #003A8F!important;
  }
  .fa-cc-mastercard {
    color: #0a3a82!important;
  }
  .fa-cc-paypal {
    color: #253b80!important;
  }
  .fa-cc-stripe {
    color: #00afe1!important;
  }
  .fa-cc-visa {
    color: #0157a2!important;
  }
  .fa-chrome {
    color: #4587F3!important;
  }
  .fa-codepen {
    color: #000!important;
  }
  .fa-connectdevelop {
    color: #391448!important;
  }
  .fa-contao {
    color: #eb8623!important;
  }
  .fa-creative-commons {
    color: #231f20!important;
  }
  .fa-css3 {
    color: #1680C0!important;
  }
  .fa-dashcube {
    color: #7f7f7f!important;
  }
  .fa-delicious {
    color: #3399ff!important;
  }
  .fa-deviantart {
    color: #4e6252!important;
  }
  .fa-digg {
    color: #000!important;
  }
  .fa-dribbble {
    color: #444444!important;
  }
  .fa-dropbox {
    color: #007ee5!important;
  }
  .fa-drupal {
    color: #0077c0!important;
  }
  .fa-empire {
    color: #000!important;
  }
  .fa-expeditedssl {
    color: #343433!important;
  }
  .fa-facebook {
    color: #3b5998!important;
  }
  .fa-facebook-official {
    color: #3b5998!important;
  }
  .fa-facebook-square {
    color: #3b5998!important;
  }
  .fa-firefox {
    color: #e66000!important;
  }
  .fa-flickr {
    color: #ff0084!important;
  }
  .fa-fonticons {
    color: #1C1E29!important;
  }
  .fa-forumbee {
    color: #83ad13!important;
  }
  .fa-foursquare {
    color: #0072b1!important;
  }
  .fa-ge {
    color: #000!important;
  }
  .fa-get-pocket {
    color: #d3505a!important;
  }
  .fa-gg {
    color: #000!important;
  }
  .fa-gg-circle {
    color: #000!important;
  }
  .fa-git {
    color: #333!important;
  }
  .fa-git-square {
    color: #333!important;
  }
  .fa-github {
    color: #333!important;
  }
  .fa-github-alt {
    color: #333!important;
  }
  .fa-github-square {
    color: #333!important;
  }
  .fa-gittip {
    color: #663300!important;
  }
  .fa-google {
    color: #4285f4!important;
  }
  .fa-google-plus {
    color: #dd4b39!important;
  }
  .fa-google-plus-square {
    color: #dd4b39!important;
  }
  .fa-google-wallet {
    color: #4285f4!important;
  }
  .fa-hacker-news {
    color: #ff6600!important;
  }
  .fa-houzz {
    color: #7ac142!important;
  }
  .fa-html5 {
    color: #e34f26!important;
  }
  .fa-instagram {
    color: #3f729b!important;
  }
  .fa-internet-explorer {
    color: #1EBBEE!important;
  }
  .fa-ioxhost {
    color: #faa729!important;
  }
  .fa-joomla {
    color: #142849!important;
  }
  .fa-jsfiddle {
    color: #4679BD!important;
  }
  .fa-lastfm {
    color: #c3000d!important;
  }
  .fa-lastfm-square {
    color: #c3000d!important;
  }
  .fa-leanpub {
    color: #0c0c0c!important;
  }
  .fa-linkedin {
    color: #0976b4!important;
  }
  .fa-linkedin-square {
    color: #0976b4!important;
  }
  .fa-linux {
    color: #333333!important;
  }
  .fa-maxcdn {
    color: #FF6600!important;
  }
  .fa-meanpath {
    color: #538ed7!important;
  }
  .fa-medium {
    color: #000!important;
  }
  .fa-odnoklassniki {
    color: #ed812b!important;
  }
  .fa-odnoklassniki-square {
    color: #ed812b!important;
  }
  .fa-opencart {
    color: #2AC2EF!important;
  }
  .fa-openid {
    color: #F78C40!important;
  }
  .fa-opera {
    color: #cc0f16!important;
  }
  .fa-optin-monster {
    color: #83c11f!important;
  }
  .fa-pagelines {
    color: #000!important;
  }
  .fa-paypal {
    color: #253b80!important;
  }
  .fa-pied-piper {
    color: #2f9f46!important;
  }
  .fa-pied-piper-alt {
    color: #2f9f46!important;
  }
  .fa-pied-piper-square {
    color: #2f9f46!important;
  }
  .fa-pinterest {
    color: #cc2127!important;
  }
  .fa-pinterest-p {
    color: #cc2127!important;
  }
  .fa-pinterest-square {
    color: #cc2127!important;
  }
  .fa-qq {
    color: #000!important;
  }
  .fa-ra {
    color: #000!important;
  }
  .fa-rebel {
    color: #000!important;
  }
  .fa-reddit {
    color: #ff4500!important;
  }
  .fa-reddit-square {
    color: #ff4500!important;
  }
  .fa-renren {
    color: #005EAC!important;
  }
  .fa-safari {
    color: #1B88CA!important;
  }
  .fa-sellsy {
    color: #1f78b9!important;
  }
  .fa-share-alt {
    color: #01bf01!important;
  }
  .fa-share-alt-square {
    color: #01bf01!important;
  }
  .fa-shirtsinbulk {
    color: #dd3a26!important;
  }
  .fa-simplybuilt {
    color: #000!important;
  }
  .fa-skyatlas {
    color: #00adbb!important;
  }
  .fa-skype {
    color: #00aff0!important;
  }
  .fa-slack {
    color: #0f7965!important;
  }
  .fa-slideshare {
    color: #e98325!important;
  }
  .fa-soundcloud {
    color: #f80!important;
  }
  .fa-spotify {
    color: #7ab800!important;
  }
  .fa-stack-exchange {
    color: #000!important;
  }
  .fa-stack-overflow {
    color: #fe7a15!important;
  }
  .fa-steam {
    color: #0B0B0B!important;
  }
  .fa-steam-square {
    color: #0B0B0B!important;
  }
  .fa-stumbleupon {
    color: #eb4924!important;
  }
  .fa-stumbleupon-circle {
    color: #eb4924!important;
  }
  .fa-tencent-weibo {
    color: #74AF2C!important;
  }
  .fa-trello {
    color: #256a92!important;
  }
  .fa-tripadvisor {
    color: #589442!important;
  }
  .fa-tumblr {
    color: #35465c!important;
  }
  .fa-tumblr-square {
    color: #35465c!important;
  }
  .fa-twitch {
    color: #6441a5!important;
  }
  .fa-twitter {
    color: #55acee!important;
  }
  .fa-twitter-square {
    color: #55acee!important;
  }
  .fa-viacoin {
    color: #333!important;
  }
  .fa-vimeo {
    color: #1ab7ea!important;
  }
  .fa-vimeo-square {
    color: #1ab7ea!important;
  }
  .fa-vine {
    color: #00b488!important;
  }
  .fa-vk {
    color: #45668e!important;
  }
  .fa-wechat {
    color: #93d034!important;
  }
  .fa-weibo {
    color: #E71D34!important;
  }
  .fa-weixin {
    color: #93d034!important;
  }
  .fa-wikipedia-w {
    color: #000!important;
  }
  .fa-windows {
    color: #00bcf2!important;
  }
  .fa-wordpress {
    color: #21759b!important;
  }
  .fa-xing {
    color: #026466!important;
  }
  .fa-xing-square {
    color: #026466!important;
  }
  .fa-y-combinator {
    color: #F0652F!important;
  }
  .fa-yc {
    color: #F0652F!important;
  }
  .fa-yahoo {
    color: #400191!important;
  }
  .fa-yelp {
    color: #af0606!important;
  }
  .fa-youtube {
    color: #e52d27!important;
  }
  .fa-youtube-play {
    color: #e52d27!important;
  }
  .fa-youtube-square {
    color: #e52d27!important;
  }
}

.giphy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    width: 80% !important;
    height: unset !important;
    aspect-ratio: 1 !important;
  }
}

@media screen and (max-width: 767px) {
  .request-page .content-fluid .card .card-body .payment__icons {
    grid-template-columns: repeat(3, 1fr);
  }
}
