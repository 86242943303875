//
// Timeline
//

// Base
.timeline {

  &.timeline-3 {
    --img-border: #EBEDF3;
    .timeline-items {
      .timeline-item {
        &:last-child {
          border-left-color: transparent;
          padding-bottom: 0;
        }
        margin-left: 21px;
        width: calc( 100% - 21px );
        border-left: 2px solid #EBEDF3;
        padding: 0 0 15px 27px;
        position: relative;
        .timeline-media {
          position: absolute;
          top: 0;
          left: -22px;
          border: 2px solid var(--img-border);
          border-radius: 100%;
          width: 42px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          line-height: 0;
        }
        .timeline-content {
          &:before {
            all: unset;
          }
          border-radius: 0.85rem;
          position: relative;
          background-color: #F3F6F9;
          padding: 0.75rem 1.5rem;
        }
      }
    }
  }
  // Item
  .timeline-item {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;

    &:last-child {
      .timeline-line {
        bottom: 100%;
      }
    }
  }

  // Line
  .timeline-line {
    display: block;
    content: ' ';
    justify-content: center;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translate(50%);
    border-left-width: 1px;
    border-left-style: dashed;
    border-left-color: $gray-300;
  }

  // Icon
  .timeline-icon {
    z-index: 1;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  // Content
  .timeline-content {
    width: 100%;
    overflow: auto;
    margin-bottom: 1.5rem;
  }

  // Vertical center
  &.timeline-center {
    .timeline-item {
      align-items: center;

      &:first-child {
        .timeline-line {
          top: 50%;
        }
      }

      &:last-child {
        .timeline-line {
          bottom: 50%;
        }
      }
    }
  }
}
